.container-deal {
  position: relative;
  padding: 0 0 25px 0;
}

.container-deal__close-icon {
  position: absolute;
  right: 0;
  top: 25px;
  width: 22px;
  height: 22px;
  cursor: pointer;
  transition: 0.4s;
}

.container-deal__close-icon::after {
  width: 2px;
  height: 28px;
  position: absolute;
  right: 10px;
  top: -3px;
  background: #9b9b9b;
  transform: rotate(45deg);
  content: '';
  display: inline-block;
}

.container-deal__close-icon::before {
  width: 2px;
  height: 28px;
  position: absolute;
  right: 10px;
  top: -3px;
  background: #9b9b9b;
  transform: rotate(135deg);
  content: '';
  display: inline-block;
}

.deal-header {
  display: flex;
  flex-direction: column;
}

.deal-header__breadcrump {
  margin-bottom: 15px;
}

.deal-header__btn-back {
  box-sizing: border-box;
  height: 32px;
  width: 92px;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  background: var(--bu-white);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-family: var(--bu-font-regular);
  font-size: 14px;
  color: #4a4a4a;
  transition: 0.5s;
}

.deal-header__btn-back:focus {
  outline: none;
}

.deal-header__btn-back:hover {
  background: #327cff;
  color: #fff;
}

.deal-header__breadcrump-info {
  display: inline-block;
  padding: 0 20px;
  font-family: var(--bu-font-regular);
  font-size: 15px;
  color: #9b9b9b;
}

.deal-header__breadcrump-info-bold {
  color: #000;
}

.deal-header__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px 15px;
}

.deal-header__top-left {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex: 1;
  min-width: 0;
}

.deal-header__top-right {
  display: flex;
  align-items: center;
  min-width: fit-content;
}

.deal-header-title {
  color: var(--bu-gray-900);
  margin-right: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.deal-header-link {
  background-color: var(--bu-primary-500);
  padding: 7px 12px 6px 12px;
  color: var(--bu-white);
  transition: 0.4s;
  border-radius: var(--bu-control-border-radius);
  font-size: var(--bu-font-size-small);
  margin-right: 5px;
}

.deal-header-link-empty {
  color: var(--bu-primary-500);
  padding-top: 4px;
  margin-right: 5px;
}

.deal-header-link:focus {
  outline: none;
  text-decoration: none;
  color: var(--bu-white);
}

.deal-header-link:hover {
  background-color: var(--bu-primary-500);
  color: var(--bu-white);
}

.deal-header-amount {
  color: var(--bu-primary-500);
  font-size: var(--bu-font-size-big);
  font-weight: 600;
  margin-right: 18px;
}

.deal-header-icons {
  width: 24px;
  height: 24px;
  overflow: hidden;
  cursor: pointer;
}

.deal-header-icons img {
  width: 100%;
  height: 100%;
}

.deal-header-tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 20px 0;
  padding: 0 10px 10px;
  background-color: var(--bu-primary-100);
}

.deal-header-tabs_item {
  color: var(--bu-gray-900);
  padding: 8px 20px 0px 0px;
  cursor: default;
}

.deal-header-tabs_item--black {
  width: 100%;
  font-weight: 500;
  min-height: 33px;
  display: flex;
  align-items: center;
  font-family: var(--bu-font-medium);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.deal-header-tabs_item:first-child {
  padding-left: 0;
}

.deal-header-tabs_item:last-child {
  border-right: none;
  flex-grow: 1;
}

.deal-header-score {
  margin-bottom: 10px;
  color: #666666;
  font-family: var(--bu-font-regular);
  font-size: 16px;
}

.deal-header-score__title {
  font-family: var(--bu-font-regular);
  font-size: 16px;
  color: #9b9b9b;
  margin-right: 5px;
}

.deal-header-score__orange {
  font-family: var(--bu-font-regular);
  font-size: 16px;
  color: #ee724e;
  margin-right: 5px;
  font-weight: 700;
}

.deal-header-score__black {
  font-family: var(--bu-font-regular);
  font-size: 16px;
  color: #000;
  margin-right: 5px;
  font-weight: 700;
}

.deal-header-other {
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
}

.deal-header-other__info {
  margin-right: 60px;
  display: flex;
  flex-direction: row;
}

.deal-header-other__info-subtitle {
  color: #9b9b9b;
  font-family: var(--bu-font-regular);
  font-size: 16px;
  margin-right: 10px;
}

.deal-header__indicators-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 5px;
}

.deal-header-other__info-indicator {
  display: block;
  width: 35px;
  height: 10px;
  margin-right: 5px;
}

.deal-header-other__info-indicator.orange {
  background: #f1a525;
}

.deal-header-other__info-indicator.gray {
  background: #dddddd;
}

.deal-header-other__info-indicator.red {
  background: #ee724e;
}

.deal-header__indicators-title.red {
  color: #ee724e;
}

.deal-header__indicators-title.orange {
  color: #f18525;
}

.deal-header__indicators-title.green {
  color: #0ab3b0;
}

.deal-header__indicators-title {
  font-family: var(--bu-font-regular);
  font-size: 16px;
}

.deal-header-other__info-text {
  color: #4a4a4a;
  font-family: var(--bu-font-regular);
  font-size: 16px;
}

.deal-body__list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.deal-body__list_item {
  width: 49%;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: var(--bu-white);
  margin-bottom: 20px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  min-height: 218px;
}

.deal-body__list_item.height {
  min-height: auto;
}

.deal-body__list_item-body {
  padding: 10px 25px 0px 25px;
  flex-grow: 1;
  flex-shrink: 0;
}

.deal-body__list_item-body:last-child {
  padding-bottom: 15px;
}

.deal-body__list_item-body-text {
  color: #000;
  font-family: var(--bu-font-regular);
  font-size: 16px;
  /* font-weight: 600; */
  margin-bottom: 10px;
}

.deal-body__list_item-body-text.orange {
  color: #ee724e;
}

.deal-body__list_item-body-subtitle {
  color: #666666;
  font-family: var(--bu-font-regular);
  font-weight: 800;
  font-size: 16px;
}

.deal-body__list_item__audio {
  margin-bottom: 21px;
}

.deal-body__list_item__audio-item {
  cursor: pointer;
  padding: 3px 37px 0 18px;
  border-radius: 15px;
  height: 30px;
  width: auto;
  display: inline-block;
  color: #4a4a4a;
  font-family: var(--bu-font-regular);
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #ccc;
  position: relative;
  margin-right: 12px;
  margin-bottom: 5px;
}

.deal-body__list_item__audio-item-icon {
  position: absolute;
  right: -2px;
  top: -1px;
  width: 30px;
  height: 30px;
}

.deal-body__list_item__audio-item-icon img {
  width: 30px;
  height: 30px;
}

.deal-body__list_item__audio-item-time {
  display: inline-block;
  color: #9b9b9b;
  font-family: var(--bu-font-regular);
  font-size: 14px;
}

.deal-body__list_item__audio-subtitle {
  color: #9b9b9b;
  font-family: var(--bu-font-regular);
  font-size: 16px;
  margin-right: 5px;
  display: inline-block;
}

.deal-body__list_item__audio-text {
  color: #4a4a4a;
  font-family: var(--bu-font-regular);
  font-size: 16px;
  margin-right: 5px;
  display: inline-block;
}

.deal-body__items_row {
  margin-bottom: 18px;
}

.deal-body__items_row-title {
  color: #9b9b9b;
  font-family: var(--bu-font-regular);
  font-size: 14px;
  margin-bottom: 10px;
}

.deal-body__items_row_list {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
}

.deal-body__items_row_list-items {
  padding: 5px 18px;
  border-radius: 15px;
  margin-right: 4px;
  margin-bottom: 9px;
  color: #4a4a4a;
  font-family: var(--bu-font-regular);
  font-size: 14px;
}

.deal-body__items_row_list-items.turquoise {
  background: #cef0ef;
}

.deal-body__items_row_list-items.pink {
  background: #fce3dc;
}

.deal-body__items_row_list-items.gray {
  background: #f7f7f7;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 15px;
}

.deal-body__items_row_list-items.white {
  background: #fff;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 15px;
}

/* TABLES */
.deal-body-table {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  border-radius: 4px;
  background-color: var(--bu-white);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 21px;
}

.deal-body-table--headerTitle {
  padding: 13px 20px;
  color: #4a4a4a;
  font-family: var(--bu-font-regular);
  font-size: 18px;
  font-weight: 600;
  border-bottom: 2px solid #ccc;
}

.deal-body-table--header {
  padding: 10px 20px;
  background: #f4f7f8;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  font-family: var(--bu-font-regular);
  color: #777;
  border-bottom: 1px solid #f1a525;
}

.deal-body-table--header.blue {
  padding: 10px;
}
.deal-body-table--header_column span {
  cursor: pointer;
}

.deal-body-table--header_column {
  text-transform: uppercase;
}

.deal-body-table--header_column.one {
  width: 19%;
  display: flex;
}

.deal-body-table--header_column.two {
  width: 17%;
  display: flex;
}

.deal-body-table--header_column.three {
  width: 30%;
  display: flex;
}

.deal-body-table--header_column.equal {
  width: 25%;
  display: flex;
  text-transform: uppercase;
  position: relative;
  padding-right: 10px;
}

.deal-body-table--header_column.flat {
  width: 20%;
  display: flex;
  position: relative;
  text-transform: uppercase;
}

.deal-body-table--header_column.full {
  width: 62%;
  display: flex;
  text-transform: uppercase;
}

.deal-body-table--header_column.flat span {
  cursor: pointer;
}

.deal-body-table--header_column.flat:last-child {
  position: inherit;
}

.header-icon--rotate {
  transform: rotate(180deg);
  margin: -1px 0 4px 0;
  margin-bottom: 3px !important;
}

.deal-body-table--body {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  padding: 14px 25px;
  border-bottom: 1px solid #dddddd;
}

.deal-body-table--body.flat {
  padding: 14px 0;
}

.deal-body-table--body:nth-child(2n + 1) {
  background: var(--bu-gray-100);
}

.deal-body-table--body-column.one {
  width: 19%;
}

.deal-body-table--body-column.two {
  width: 17%;
}

.deal-body-table--body-column.three {
  width: 30%;
  /* padding-left: 10px; */
}

.deal-body-table--body-column.equal {
  width: 25%;
}

.deal-body-table--body-column.flat {
  width: 20%;
}

.deal-body-table--body-column.full {
  width: 62%;
}

/* .deal-body-table--body-column.flat:after {
  position: absolute;
  right: 3px;
  top: -15px;
  bottom: -15px;
  width: 1px;
  background: white;
  content: '';
} */

.deal-body-table--body-column.one .callTranscript__table-body-item-name.width {
  white-space: pre-wrap;
  max-width: 100%;
  display: block;
  word-wrap: break-word;
  /* padding-right: 10px; */
}

.deal-body-table--body-column.one.width {
  width: 32%;
  overflow: hidden;
}

.deal-body-table--header_column.one.width {
  width: 48%;
}

.deal-body-table-LastMentioned {
  display: flex;
  align-items: center;
  color: #666;
  font-family: var(--bu-font-regular);
  font-weight: 800;
  font-size: 16px;
}

.deal-body-table-LastMentioned-icon-item {
  width: 24px;
  height: 24px;
  margin-left: 10px;
  cursor: pointer;
}

.deal-body-table--body-column {
  color: #666;
  font-family: var(--bu-font-regular);
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  padding-right: 10px;
  word-wrap: break-word;
  overflow: hidden;
}

.deal-body-table--body-column .text--blue {
  color: #0098da;
  font-style: italic;
  margin-left: 5px;
}

.table-body-item-keywords-btn {
  display: inline-block;
  cursor: pointer;
  padding: 2px 10px;
  color: #327cff;
  font-size: 14px;
  font-family: var(--bu-font-regular);
  font-weight: 800;
  background: #fff;
  border: 1px solid #327cff;
  border-radius: 15px;
  margin: 0 5px 6px 0;
  /* box-shadow: 0 0 4px 0 rgba(0,0,0,0.5); */
}

.table-body-item-keywords-btn:focus {
  outline: none;
}

.table-body-item-blue-text {
  color: #0098da;
  font-size: 16px;
  font-family: var(--bu-font-regular);
  /* font-weight: 800; */
}

.deal-body__audio-item {
  margin-bottom: 20px;
}

.deal-body__audio-item-link {
  display: inline-block;
  padding: 3px 35px 5px 20px;
  border: 1px solid #f1a525;
  border-radius: 15px;
  position: relative;
  font-family: var(--bu-font-regular);
  font-size: 16px;
  font-weight: 600;
  color: #4a4a4a;
  cursor: pointer;
  margin-right: 20px;
}

.deal-body__audio-item-link--no-show {
  opacity: 0.6;
}

.deal-body__audio-item-link-event {
  padding-right: 20px;
  margin-right: 35px;
  cursor: default;
}

.deal-body__audio-item-link-img {
  position: absolute;
  right: 3px;
  top: 2px;
  width: 24px;
  height: 24px;
}

.deal-body__audio-item-days,
.deal-body__audio-item-no-show {
  margin-top: 10px;
  display: inline-block;
  color: #9b9b9b;
  font-family: var(--bu-font-regular);
  font-weight: 600;
  font-size: 14px;
}

.deal-body__audio-item-no-show {
  color: #ee724e;
}

.deal-body__audio-item-info {
  margin-top: 10px;
  color: #666666;
  font-family: var(--bu-font-regular);
  font-size: 15px;
  word-wrap: break-word;
}

.deal-body__box {
  padding: 5px 25px 10px 25px;
}

.deal-body__box__btnMore {
  color: #327cff;
  font-family: var(--bu-font-regular);
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #327cff;
  border-radius: 14px;
  background-color: var(--bu-white);
  cursor: pointer;
  transition: 0.4s;
  padding: 4px 15px;
}

.deal-body__box__btnMore:hover {
  color: #fff;
  background-color: #327cff;
}

.table-body-btn.no--margin {
  margin-top: -1px;
}

.fullWidth_header-table {
  padding: 15px;
  border: 1px solid #ddd;
  margin-bottom: -1px;
  font-family: var(--bu-font-regular);
  font-size: 18px;
  font-weight: 600;
  color: #4a4a4a;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
}

.deal-body-table--last-email-summary {
  white-space: nowrap;
  color: #4a4a4a;
  font-size: 15px;
  line-height: 20px;
  font-weight: 800;
  font-family: var(--bu-font-regular);
}

.deal-body-next_steps {
  display: inline-block;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 4px;
  min-width: 261px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  font-family: var(--bu-font-regular);
  font-size: 16px;
  color: #666;
  align-items: center;
}

.deal-body-next_steps .next_steps_red {
  color: #f05a30;
}

.deal-body-next_steps .title {
  background-color: #f9f9f9;
  min-height: 40px;
  padding: 10px 25px;
  font-weight: 600;
}

.deal-body-next_steps .description {
  display: block;
  padding: 10px 25px 10px 25px;
}

.custom_btn_rightPanel {
  position: absolute;
  left: -87px;
  top: 77px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 86px;
  height: 47px;
  background: #00a1e0;
  border-radius: 3px 0 0 3px;
  box-shadow: 0px 1px 10px 0px rgba(0, 161, 224, 0.7);
}

.custom_btn_rightPanel img {
  width: 30px;
}

.custom_btn_rightPanel i {
  color: #fff;
}

.right_panel {
  position: fixed;
  right: -200px;
  top: 0;
  width: 200px;
  height: 100%;
  background: #fff;
  border-left: 1px solid #ddd;
  transition: 0.5s;
}

.right_panel.open {
  right: 0;
}

.panel_header {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.deal-header-remap-button {
  cursor: pointer;
  margin-left: 20px;
  font-size: 15px;
  background: #f05a30;
  padding: 6px 10px;
  color: #fff;
  font-family: var(--bu-font-regular);
  transition: 0.4s;
  border-radius: 2px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
}
